/* eslint sort-keys: ["error", "asc"] */
import {
  mdiAccountArrowRight,
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiAlert,
  mdiBookOpenOutline,
  mdiCamera,
  mdiCashCheck,
  mdiCashPlus,
  mdiCertificateOutline,
  mdiCheck,
  mdiCheckCircle,
  mdiClose,
  mdiCloseCircle,
  mdiCloudUploadOutline,
  mdiCommentTextOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFaceMan,
  mdiFaceWoman,
  mdiFileDocumentOutline,
  mdiFileUpload,
  mdiFileUploadOutline,
  mdiHandCoinOutline,
  mdiImageOutline,
  mdiMinus,
  mdiPencilOutline,
  mdiPlus,
  mdiPrinterOutline,
  mdiSquareEditOutline,
  mdiHeartOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiChartTimelineVariant,
  mdiWeatherNight,
  mdiWeatherSunny,
  mdiCastEducation,
  mdiCurrencyRupee,
  mdiSchool,
} from '@mdi/js'

export default {
  mdiAccountArrowRight,
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiAlert,
  mdiBookOpenOutline,
  mdiCamera,
  mdiCashCheck,
  mdiCashPlus,
  mdiCastEducation,
  mdiCertificateOutline,
  mdiChartTimelineVariant,
  mdiChatOutline,
  mdiCheck,
  mdiCheckCircle,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCloudUploadOutline,
  mdiCogOutline,
  mdiCommentTextOutline,
  mdiCurrencyRupee,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailOutline,
  mdiExportVariant,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFaceMan,
  mdiFaceWoman,
  mdiFileDocumentOutline,
  mdiFileUpload,
  mdiFileUploadOutline,
  mdiHandCoinOutline,
  mdiHeartOutline,
  mdiHelpCircleOutline,
  mdiImageOutline,
  mdiLogoutVariant,
  mdiMinus,
  mdiPencilOutline,
  mdiPlus,
  mdiPrinterOutline,
  mdiSchool,
  mdiSquareEditOutline,
  mdiWeatherNight,
  mdiWeatherSunny,
}
